import styles from "components/testimonials/Testimonials.module.scss";
import { ReactComponent as TwentyOneEnergyLogo } from "components/testimonials/logos/21energy-logo.svg";
import { ReactComponent as EbeaLogo } from "components/testimonials/logos/ebea-logo.svg";
import { ReactComponent as FulgurLogo } from "components/testimonials/logos/fulgur_logo_white.svg";
import { ReactComponent as RunWayLogo } from "components/testimonials/logos/runway-logo.svg";
import { ReactComponent as SeeteeLogo } from "components/testimonials/logos/seetee-logo.svg";
import { ReactComponent as WolfLogo } from "components/testimonials/logos/wolf-logo.svg";

export function Testimonials() {
  return (
    <div className={styles.testimonialsWrapper}>
      <div className={styles.label}>Trusted by</div>
      <div className={styles.testimonialsGrid}>
        <div className={styles.wideLogo}>
          <EbeaLogo />
        </div>
        <div className={styles.wideLogo}>
          <TwentyOneEnergyLogo />
        </div>
        <div className={styles.logo}>
          <WolfLogo />
        </div>
        <div className={styles.logo}>
          <FulgurLogo />
        </div>
        <div className={styles.wideLogo}>
          <RunWayLogo />
        </div>
        <div className={styles.logo}>
          <SeeteeLogo />
        </div>
      </div>
    </div>
  );
}
